<template>
  <div class="standarddetail-detail-container">
    <p class="invalid">该页面为预览效果，短期内将会失效</p>
    <div class="head">
      <div class="top">
        <span class="type">[标准]</span>
        <span class="title">{{ detailsData.standardName }}</span>
      </div>
      <IconButton1
        v-if="detailsData.id"
        :every="tempData"
        :curcer="detailsData.id"
      ></IconButton1>
    </div>
    <ul class="content">
      <li v-if="detailsData.artSummary" class="msg">
        <div>摘要：</div>
        <span>
          {{ detailsData.artSummary }}
        </span>
      </li>
      <li v-if="detailsData.keyword" class="msg">
        <div>关键词：</div>
        <span>
          {{ detailsData.keyword }}
        </span>
      </li>
      <li v-if="detailsData.standardNumber" class="msg">
        <div>标准编号：</div>
        <span>
          {{ detailsData.standardNumber }}
        </span>
      </li>
      <li v-if="detailsData.standardNature" class="msg">
        <div>标准性质：</div>
        <span>
          {{ detailsData.standardNature }}
        </span>
      </li>
      <li v-if="detailsData.standardStatus" class="msg">
        <div>状态：</div>
        <span>
          {{ detailsData.standardStatus }}
        </span>
      </li>
      <li v-if="detailsData.publishTime" class="msg">
        <div>发布日期：</div>
        <span>
          {{ detailsData.publishTime.slice(0,10) }}
        </span>
      </li>
      <li v-if="detailsData.implementationTime" class="msg">
        <div>实施日期：</div>
        <span>
          {{ detailsData.implementationTime.slice(0,10) }}
        </span>
      </li>
      <li v-if="detailsData.competentDep" class="msg">
        <div>主管部门：</div>
        <span>
          {{ detailsData.competentDep }}
        </span>
      </li>
      <li v-if="detailsData.publishUnit" class="msg">
        <div>发布单位：</div>
        <span>
          {{ detailsData.publishUnit }}
        </span>
      </li>
      <li v-if="detailsData.putUnderUnit" class="msg">
        <div>归口单位：</div>
        <span>
          {{ detailsData.putUnderUnit }}
        </span>
      </li>
      <li v-if="detailsData.draftUser" class="msg">
        <div>起草人：</div>
        <span
          class="draftUser"
          v-for="(v, i) in detailsData.draftUser.split(',')"
          :key="i"
          >{{ v }}
        </span>
      </li>
      <li v-if="detailsData.draftUnit" class="msg">
        <div>起草单位：</div>
        <span>
          {{ detailsData.draftUnit }}
        </span>
      </li>
      <li v-if="detailsData.technicalCommittee" class="msg">
        <div>标准技术委员会：</div>
        <span>
          {{ detailsData.technicalCommittee }}
        </span>
      </li>
      <li v-if="detailsData.chinaClassifyNumber" class="msg">
        <div>中国标准分类号：</div>
        <span>
          {{ detailsData.chinaClassifyNumber }}
        </span>
      </li>
      <li v-if="detailsData.internationalClassifyNumber" class="msg">
        <div>国际标准分类号：</div>
        <span>
          {{ detailsData.internationalClassifyNumber }}
        </span>
      </li>
      <li v-if="detailsData.pageCount" class="msg">
        <div>总页数：</div>
        <span>
          {{ detailsData.pageCount }}
        </span>
      </li>
      <li v-if="detailsData.paperPricing" class="msg">
        <div>纸质版定价：</div>
        <span>
          {{ detailsData.paperPricing }}
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from "vuex";
import IconButton1 from "../components/IconButton1.vue";
import details from "@/mixins/details.js";
export default {
  components: {
    IconButton1,
  },
  mixins: [details],
  data() {
    return {
      detailsData: {},
      otherList: [],
    };
  },
  computed: {
    ...mapState(["table"]),
    data1() {
      return this.detailsData.lagaList.map((item) => {
        return {
          lagalMessage: item.lagalMessage,
          lagalStatus: item.lagalStatus,
          lagalPublishTime: item.lagalPublishTime,
        };
      });
    },
  },
  created() {
    this.getDetails({
      articleType: this.$route.query.articleType,
      articleId: this.$route.query.articleId,
      projectId: 3,
    });
  },
};
</script>

<style lang="scss" scoped>
.standarddetail-detail-container {
  .invalid {
    margin:0 auto 50px;
    text-align: center;
    width: 396px;
    height: 45px;
    line-height: 45px;
    background: #f9f9f9;
    border-radius: 4px;
    font-size: 14px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #333333;
  }
  .head {
    padding-bottom: 26px;
    border-bottom: 1px dashed #d6d6d6;
    .top {
      display: flex;
      align-items: center;
      .type {
        font-size: 30px;
        color: #999999;
      }
      .title {
        font-size: 30px;
        color: #333333;
        margin: 0 5px;
      }
      .column {
        display: inline-block;
        width: 76px;
        height: 30px;
        background: #00a4ff;
        border: 1px solid #00a4ff;
        border-radius: 11px;
        font-size: 14px;
        color: #ffffff;
        text-align: center;
        line-height: 30px;
      }
    }
    .auther {
      font-size: 14px;
      color: #999999;
      margin-top: 14px;
      span {
        font-size: 14px;
        color: #333333;
      }
    }
    .unit {
      margin-top: 14px;
      font-size: 14px;
      color: #999999;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      span {
        font-size: 14px;
        color: #333333;
      }
    }
  }
  .content {
    border-bottom: 1px solid #d6d6d6;
    padding-bottom: 40px;
    .msg {
      margin-top: 39px;
      div {
        font-size: 16px;
        font-weight: bold;
        color: #333333;
      }
      span {
        margin-top: 10px;
        font-size: 16px;
        color: #333333;
      }
      .draftUser {
        color: #00a4ff;
        cursor: pointer;
      }
    }
  }
}
</style>
